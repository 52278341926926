import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {EntDashboard} from '../EntDashboard'
import ProfilePage from '../../modules/profile/client_profile/ProfilePage'
import {UserModel} from '../../../dataTypes'
import {AdminDashboard} from '../AdminDashboard'
import {EntManagerDashboard} from '../EntManagerDashboard'

const DashboardPage: FC<{user: UserModel}> = ({user}) => {
  const intl = useIntl()
  let dashboardComponent = null

  if (user['user type'] == 'admin') {
    dashboardComponent = <AdminDashboard />
  } else if (user['user type'] == 'leadership') {
    dashboardComponent = <EntDashboard />
  } else if (user['user type'] == 'entity-editor') {
    dashboardComponent = <EntManagerDashboard />
  } else if (user['user type'] == 'entity-viewer') {
    dashboardComponent = <EntDashboard />
  } else if (user['user type'].includes('entity')) {
    dashboardComponent = <EntDashboard />
  } else if (user['user type'] == 'employee') {
    dashboardComponent = <ProfilePage />
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {dashboardComponent}
    </>
  )
}

const DashboardWrapper: FC<{user: UserModel}> = ({user}) => (
  <>
    <PageTitle breadcrumbs={[]} />
    <DashboardPage user={user} />
  </>
)

export {DashboardWrapper}
