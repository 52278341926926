/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import WarningSpinner from '../../../../app/modules/profile/components/WarningSpinner'
import {TableWithScore} from './TableWithScore'
import {GradeRatios} from '../../../../dataTypes'
import {Link} from 'react-router-dom'
import {useCallInterval} from '../../../../CallTimeIntervalContext'

type Props = {
  className: string
  title?: string
}

type Employee = {
  name: string
  position: string
  division: string
  department: string
  section: string
  score: number
  technicalScore: number
  appraisalScore: number
  lineManagerScore: number
  potentialSkillsScore: number
  dob: string
  gender: string
  nationality: string
  talentPool: string
  email: string
  sector: string
  yearsOfExp: string

  employeeId: string
  employeeNumber: string
}

const TalentInfoTable: React.FC<Props> = ({className, title}) => {
  const [gradeRatios, setGradeRatios] = useState<GradeRatios>()
  const [originalData, setOriginalData] = useState<Employee[]>([])
  const {user} = useUserContext()
  const [showLoading, setShowLoading] = useState(false)
  const [showLoadedFirstTime, setShowLoadedFirstTime] = useState(false)
  const {callInterval} = useCallInterval()

  const warningTime = 3000
  const cardLoadTime = 1000
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  async function callEmpData() {
    if (user?.['user id']) {
      const allEmpDataSet = await fetch(config.domainName + ':' + config.port + '/api/employee', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          entity_id: user['user id'],
        }),
      })

      const allEmpDataCont = await allEmpDataSet.json()

      const employeeData = allEmpDataCont.employees.map((emp: any) => {
        if (emp['employee score'] !== '-') {
          const empScorePercentage = Math.round(emp['employee score'] * 100)
          return {...emp, 'employee score': empScorePercentage}
        } else {
          return {...emp, 'employee score': 0}
        }
      })

      const gradeRatios: GradeRatios = {
        'appraisal skill ratio': allEmpDataCont.ratios['appraisal skill ratio'] * 100,
        'line manager skill ratio': allEmpDataCont.ratios['line manager skill ratio'] * 100,
        'potential skill ratio': allEmpDataCont.ratios['potential skill ratio'] * 100,
        'technical skill ratio': allEmpDataCont.ratios['technical skill ratio'] * 100,
      }

      setGradeRatios(gradeRatios)

      const dummyData: Employee[] = employeeData.map((employee: any) => {
        return {
          name: employee.name,
          position: employee['job title'],
          dob: employee.dob,
          division: employee['division'],
          section: employee['section'],
          department: employee['department'],
          score: employee['employee score'],
          technicalScore: employee['performance technical score'],
          potentialSkillsScore: employee['potential score'],
          appraisalScore: employee['performance appraisal score'],
          lineManagerScore: employee['line manager score'],
          gender: employee.gender,
          nationality: employee.nationality,
          talentPool: employee['talent pool'],
          email: employee.email,
          sector: employee.sector,
          yearsOfExp: employee['years of experience'],
          employeeId: employee['employee id'],
          employeeNumber: employee['employee number'],
        }
      })

      setOriginalData(dummyData)

      // if(showLoadedFirstTime == false) showLoadedFirstTime
    }
  }

  const talentArrayCat = originalData
    .filter((i) => i.talentPool === 'true')
    .map(({employeeId, name, employeeNumber, score}) => ({
      candidate: name,
      'candidate id': employeeId,
      'candidate number': employeeNumber,
      'overall score': score / 100,
    }))

  // useEffect(() => {
  //   callEmpData()
  // }, [])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     callEmpData()
  //   }, testTime)

  //   return () => clearInterval(interval)
  // }, [])
  useEffect(() => {
    async function fetchDataOnFirstLoad() {
      await callEmpData()
      setShowLoadedFirstTime(true)
    }
    fetchDataOnFirstLoad()

    if (showLoadedFirstTime) {
      const interval = setInterval(() => {
        callEmpData()
      }, callInterval)

      return () => clearInterval(interval)
    }
  }, [showLoadedFirstTime])

  const handleRefresh = () => {
    callEmpData()
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header d-flex flex-row border-0 pt-1'>
        <h3 className='card-title me-auto '>
          <Link to={'/candidate-overview?talent'}>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </Link>
        </h3>
        {/* <button className='btn btn-icon btn-primary' onClick={handleRefresh}>
          <i className='fa-solid fa-arrows-rotate'></i>
        </button> */}
      </div>

      <div className='card-body py-3' style={{maxHeight: '500px', overflow: 'auto'}}>
        {talentArrayCat.length > 0 ? (
          <TableWithScore
            tableData={talentArrayCat}
            link='/candidate-profile?employeeQuery='
            canRedirect={true}
            redirectColumnName={'candidate id'}
            hiddenColumnIndex={1}
          />
        ) : showLoading ? (
          <div className='card-body pt-0 pb-4 d-flex h-100 flex-wrap align-items-center'>
            <div className='d-flex flex-column content-justify-center flex-row-fluid'>
              <span className='text-center align-middle  fs-4 fw-medium'>
                There are no people in the talent pool
              </span>
            </div>
          </div>
        ) : (
          <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center align-items-center '>
            <span
              className='spinner-border spinner-border-lg spinner-info'
              style={{
                width: '80px',
                height: '80px',
                border: ' var(--bs-spinner-border-width) solid #01a982',
                borderRightColor: 'var(--bs-light)',
                borderWidth: '10px',
              }}
            ></span>
          </div>
        )}
      </div>
    </div>
  )
}

export {TalentInfoTable}
