/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, FC} from 'react'
import {SkillsDescription} from '../../../../../_metronic/partials/widgets'
import {SkillsTable} from '../../../../../_metronic/partials/widgets/tables/SkillsTable'
import {useUserContext} from '../../../../../UserContext'
import {PotentialSkillGroup} from '../../../../../dataTypes'
import config from '../../../../../config.json'

interface PotentialSkillsProps {
  isCompact?: boolean
  employeeQuery?: string
  className?: string
  canselect?: boolean
  selectedSkillNames?: string[] // Add the selectedSkillNames prop
  prevSelectedSkillNames?: string[] // Add the selectedSkillNames prop
  onSelectSkillNames?: (selectedSkillNames: string[]) => void // Add the onSelectSkillNames prop
  onPotentialEmpty?: (potentialEmpty: boolean) => void
  potentialEmpty?: boolean
}

export const PotentialSkills: FC<PotentialSkillsProps> = ({
  isCompact = false,
  employeeQuery,
  className,
  canselect,

  onSelectSkillNames,
  onPotentialEmpty,
  prevSelectedSkillNames,
}) => {
  const [employeeSkills, setEmployeeSkills] = useState<PotentialSkillGroup[]>([])
  const [selectedIndices, setSelectedIndices] = useState<Array<number>>([])
  const [selectedSkillNames, setSelectedSkillNames] = useState<string[]>([]) // New state for selected skill names
  const [updatedIndex, setUpdatedIndex] = useState(true)
  const [selectedCheckbox, setSelectedCheckbox] = useState(false)
  const {user} = useUserContext()
  const [showLoading, setShowLoading] = useState(false)
  const [potentialEmpty, setPotentialEmpty] = useState(false)

  const warningTime = 5000

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  async function callPotentialSkillsData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empPotential = await fetch(
        config.domainName + ':' + config.port + '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetPotentialSkills',
            employee_id: employeeQuery,
          }),
        }
      )
      const empPotentialCont = await empPotential.json()
      if (empPotentialCont['potential skills'] !== null) {
        empPotentialCont['potential skills']?.forEach((potentialskill: any) => {
          const potentialSkillGrp: PotentialSkillGroup = {
            group: potentialskill['group'],
            potentialskills: potentialskill['potentialskills'].map((skills: any) => ({
              title: skills['title'],
              score: skills['score'],
              grade: skills['grade'],
              color: skills['color'],
              description: skills['description'],
            })),
          }

          // setEmployeeSkills((prevSkills) => {
          //   // Check if the group already exists in prevSkills
          //   const existingGroup = prevSkills.find(
          //     (skill) => skill.group === potentialSkillGrp.group
          //   )
          //   if (existingGroup) {
          //     // Group already exists, return the previous state without modifications
          //     return prevSkills
          //   } else {
          //     // Group is new, add potentialSkillGrp to the previous state
          //     return [...prevSkills, potentialSkillGrp]
          //   }
          // })
        })
        setEmployeeSkills(empPotentialCont['potential skills'])
        setSelectedIndices(Array(empPotentialCont['potential skills'].length).fill(-1))
        setPotentialEmpty(false)
      } else {
        setEmployeeSkills([])
        setPotentialEmpty(true)
      }
    }
  }

  useEffect(() => {
    if (employeeQuery) {
      callPotentialSkillsData(employeeQuery)
    } else {
      callPotentialSkillsData(user?.['user id'])
    }
  }, [employeeQuery])

  const handleItemClick = (groupindex: number, itemIndex: number) => {
    setUpdatedIndex(false)
    setSelectedIndices((prevIndices) => {
      const updatedIndices = [...prevIndices]
      updatedIndices[groupindex] = itemIndex
      return updatedIndices
    })
    setUpdatedIndex(true)
    setSelectedCheckbox(true) // Update the selected checkbox state
  }
  // console.log('prev slected elems in potential page', prevSelectedSkillNames)
  const receiveSelect = (data: string, adding: boolean) => {
    if (adding) {
      setSelectedSkillNames((prevSkillNames) => {
        // Check if the new data already exists in the array
        if (prevSkillNames.includes(data)) {
          // Data already exists, return the previous state without modifications
          return prevSkillNames
        } else {
          // Data is unique, add it to the previous state
          const newSkillNames = [...prevSkillNames, data]

          return newSkillNames
        }
      })
    } else {
      setSelectedSkillNames((prevSkillNames) => {
        // Check if the new data already exists in the array
        const filteredSkillNames = prevSkillNames.filter((skill) => skill !== data)

        return filteredSkillNames
      })
    }
  }

  // Call the onSelectSkillNames prop whenever selectedSkillNames changes
  useEffect(() => {
    if (onSelectSkillNames) {
      onSelectSkillNames(selectedSkillNames || [])
    }
  }, [selectedSkillNames, onSelectSkillNames])

  useEffect(() => {
    if (onPotentialEmpty) {
      onPotentialEmpty(potentialEmpty)
    }
  }, [potentialEmpty, onPotentialEmpty])

  return (
    <div className={className + ' h-100'}>
      {employeeSkills.length !== 0 ? (
        <div className={isCompact ? 'h-100 d-flex flex-column' : ''}>
          {isCompact && (
            <div className={`card  mb-2 h-75px`}>
              <div className='card card-header border-0 pt-5 '>
                <h3 className='card-title align-items-center flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Potential Competencies</span>
                </h3>
              </div>
            </div>
          )}
          <div className={isCompact ? 'h-100 d-flex flex-row' : ''}>
            {employeeSkills.map((skillGroup, index) => (
              <div className={isCompact ? 'flex-fill col-4 mt-0' : 'col-12 mb-5 h-100'} key={index}>
                {skillGroup.potentialskills.length > 0 && (
                  <div className='h-100 d-flex flex-row '>
                    <div className={isCompact ? 'col-12 mt-0 h-100' : 'col-8 mb-5 h-100'}>
                      <SkillsTable
                        className={
                          index < employeeSkills.length - 1
                            ? 'mb-5 mb-xxl-8 h-100 me-2'
                            : 'mb-5 mb-xxl-8 h-100'
                        }
                        chartIndex={'SS-Overall-Score-Chart-' + index}
                        skills={skillGroup.potentialskills}
                        title={skillGroup.group}
                        isCompact={isCompact}
                        setSelectedIndex={(itemIndex: number) => handleItemClick(index, itemIndex)}
                        empFunc={callPotentialSkillsData}
                        reloadFuncProps={employeeQuery ? employeeQuery : user?.['user id']}
                        canselect={canselect}
                        onSelectSkills={receiveSelect}
                        prevSelectedSkillNames={prevSelectedSkillNames}
                      />
                    </div>
                    {!isCompact && (
                      <div className='col-4 mb-5'>
                        <div style={{position: 'sticky', top: '100px'}}>
                          <SkillsDescription
                            className='mb-5 mb-xxl-1'
                            skill={
                              updatedIndex
                                ? skillGroup.potentialskills[selectedIndices[index]]
                                : skillGroup.potentialskills[0]
                            }
                            selectedIndex={selectedIndices[index]}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='d-flex flex-row h-100'>
          <div style={{height: '100%', width: '100%'}}>
            <div className={`card  mb-4 mb-xxl-8 h-100`}>
              <div className='card-header border-0 pt-5 '>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Potential Skills</span>
                </h3>
              </div>
              {!isCompact ? (
                showLoading ? (
                  <div className='card-body py-3'>
                    <div className='table-responsive'>
                      <div className='d-flex justify-content-center m-5 fw-bold fs-4'>
                        There are no Potential Skills available. Please go to the Assessments tab to
                        see if you have any pending assessments!
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center  '>
                    <span
                      className='spinner-border spinner-border-lg spinner-info'
                      style={{
                        width: '80px',
                        height: '80px',
                        border: ' var(--bs-spinner-border-width) solid #01a982',
                        borderRightColor: 'var(--bs-light)',
                        borderWidth: '10px',
                      }}
                    ></span>
                  </div>
                )
              ) : showLoading ? (
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <div className='d-flex justify-content-center m-5 fw-bold fs-4'>
                      There are no Potential Skills available. Please go to the Assessments tab to
                      see if you have any pending assessments!
                    </div>
                  </div>
                </div>
              ) : (
                <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center  '>
                  <span
                    className='spinner-border spinner-border-lg spinner-info'
                    style={{
                      width: '80px',
                      height: '80px',
                      border: ' var(--bs-spinner-border-width) solid #01a982',
                      borderRightColor: 'var(--bs-light)',
                      borderWidth: '10px',
                    }}
                  ></span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
