import React, {FC, useEffect, useState} from 'react'
import {CertificatesTable} from '../../../../../_metronic/partials/widgets'
import {EmployeeCertificates} from '../../../../../dataTypes'
import config from '../../../../../config.json'
import {Buffer} from 'buffer'
import {Alert, Button, Modal} from 'react-bootstrap'
import {useUserContext} from '../../../../../UserContext'

export const Certification: FC = () => {
  const [employeeCertificates, setEmployeeCertificates] = useState<EmployeeCertificates[]>([])
  const [certificatesUpdated, setCertificatesUpdated] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [imageFeedback, setImageFeedback] = useState('')
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [showLoading, setShowLoading] = useState(false) // State to control showing the warning message

  const warningTime = 5000

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setImageFile(null)
    const formData = new FormData(event.currentTarget)
    if (user?.['user id']) {
      const newCertifcateItem: EmployeeCertificates = {
        'employee id': user['user id'],
        'expiry date': formData.get('cert-expiry') as string,
        'certificate name': formData.get('cert-name') as string,
        'certificate type': formData.get('cert-type') as string,
        'issue date': formData.get('cert-issue') as string,
        license: formData.get('cert-license') as string,
      }

      if ((formData.get('cert-image') as File).size !== 0) {
        setImageFile(formData.get('cert-image') as File)
      }
      sendCertificates(newCertifcateItem)
    }
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files && event.target.files[0]
    setImageFeedback('')

    if (file) {
      const maxSize = 2 * 1024 * 1024 // 2MB in bytes
      if (file.size <= maxSize) {
        const reader = new FileReader()
        reader.onloadend = function () {
          const result = reader.result
          if (result instanceof ArrayBuffer) {
            const arr = new Uint8Array(result)
            let header = ''
            for (let i = 0; i < arr.length; i++) {
              header += arr[i].toString(16)
            }

            if (
              header.startsWith('89504e47') || // PNG
              header.startsWith('ffd8') // JPEG
            ) {
            } else {
              setImageFeedback(
                'Invalid File format. Please select an image file with the extension .jpg or .png'
              )
            }
          } else {
            setImageFeedback(
              'Invalid File format. Please select an image file with the extension .jpg or .png'
            )
          }
        }

        // Read the file as an array buffer
        reader.readAsArrayBuffer(file)
      } else {
        // File size exceeds the limit
        setImageFeedback('The file is too large. Please select a smaller image')
      }
    }
  }

  function decodeBase64(data: any) {
    const decodedData = Buffer.from(data, 'base64')
    const blob = new Blob([decodedData], {type: 'image/jpeg'})
    const imageUrl = URL.createObjectURL(blob)
    return imageUrl
  }

  const {user} = useUserContext()
  async function callCertificates() {
    if (user?.['user id']) {
      const getCerts = await fetch(config.domainName + ':' + config.port + '/api/employeequery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'GetEmployeeCertificate',
          employee_id: user['user id'],
        }),
      })

      const getCertCont = await getCerts.json()
      if (getCertCont['message'] != null) {
        const certArray: EmployeeCertificates[] = getCertCont['message'].map((cert: any) => ({
          'certificate id': cert['certificate id'],
          'employee id': cert['employee id'],
          'certificate type': cert['certificate type'],
          'certificate name': cert['certificate name'],
          license: cert['license'],
          'issue date': cert['issue date'],
          'expiry date': cert['expiry date'],
          'img path': decodeBase64(cert['img data']),
        }))

        setEmployeeCertificates(certArray)
      }
    }
  }

  async function sendCertificates(certificate: EmployeeCertificates) {
    const data = new FormData()
    if (imageFile !== null) {
      data.append('file', imageFile)
      data.append('employee_id', certificate['employee id'])
      data.append('certificate name', certificate['certificate name'])
      data.append('certificate type', certificate['certificate name'])
      data.append('license', certificate.license)
      data.append('issue date', certificate['issue date'])
      data.append('expiry date', certificate['expiry date'])
      const sendCert = await fetch(
        config.domainName + ':' + config.port + '/api/employeecertificate',
        {
          method: 'POST',
          body: data,
        }
      )
      const sendCertCont = await sendCert.json()

      if (sendCert.status === 200) {
        setShowModal(false)
        setCertificatesUpdated(true)
      }
    } else {
      setImageFeedback('Certificate Image is required. please try again')
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (imageFeedback == 'Certificate Image is required. please try again') setImageFeedback('')
    }, 2000)
    return () => clearInterval(interval)
  }, [imageFeedback])

  useEffect(() => {
    callCertificates()
  }, [certificatesUpdated])

  return (
    <>
      <div className='row g-5 g-xxl-8 h-100'>
        {employeeCertificates.length !== 0 ? (
          <CertificatesTable
            className='mb-5 mb-xxl-8'
            employeeCerts={employeeCertificates}
            setShowAddModal={setShowModal}
          />
        ) : showLoading ? (
          <div className='d-flex flex-row'>
            <div style={{height: '100%', width: '100%'}}>
              <div className={`card  mb-4 mb-xxl-8 h-100`}>
                <div className='card-header border-0 pt-5 '>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Certificates</span>
                  </h3>
                  <div className='card-toolbar'>
                    <Button
                      onClick={() => setShowModal(true)}
                      className='btn btn-primary'
                      data-target='Form '
                    >
                      Add Certificate
                    </Button>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <div className='d-flex justify-content-center m-5 fw-bold fs-4'>
                      There is no Certificates currently uploaded. Please click the "Add
                      Certificate" button to add your Certificates!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-row'>
            <div style={{height: '100%', width: '100%'}}>
              <div className={`card  mb-4 mb-xxl-8 h-100`}>
                <div className='card-header border-0 pt-5 '>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Certificates</span>
                  </h3>
                  <div className='card-toolbar'>
                    <Button
                      onClick={() => setShowModal(true)}
                      className='btn btn-primary'
                      data-target='Form '
                    >
                      Add Certificate
                    </Button>
                  </div>
                </div>

                <div className='card-body pt-2 pb-4 d-flex justify-content-center '>
                  <span
                    className='spinner-border spinner-border-lg spinner-info'
                    style={{
                      width: '80px',
                      height: '80px',
                      border: ' var(--bs-spinner-border-width) solid #01a982',
                      borderRightColor: 'var(--bs-light)',
                      borderWidth: '10px',
                    }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <label htmlFor='cert-type'>Certificate Type:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='cert-type'
                id='cert-type'
                placeholder='Enter Certificate Type'
                style={{marginBottom: '10px'}}
              />
              <label htmlFor='cert-name'>Certificate Name:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='cert-name'
                id='cert-name'
                placeholder='Enter Certificate Name'
                style={{marginBottom: '10px'}}
              />
              <label htmlFor='cert-license'>Certificate License:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='cert-license'
                id='cert-license'
                placeholder='Enter Certificate License'
                style={{marginBottom: '10px'}}
              />
              <label htmlFor='cert-issue'>Issue Date:</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='cert-issue'
                id='cert-issue'
                placeholder='Enter Certificate Issue Date'
                style={{marginBottom: '10px'}}
              />
              <label htmlFor='cert-expiry'>Expiry Date:</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='cert-expiry'
                id='cert-expiry'
                placeholder='Enter Certificate Expiry Date'
                style={{marginBottom: '10px'}}
              />
              <label htmlFor='cert-image'>Certificate Image:</label>
              <input
                type='file'
                className='form-control form-control-lg form-control-solid'
                name='cert-image'
                id='cert-image'
                accept='image/png, image/jpeg, image/jpg'
                onChange={handleFileChange}
              />
              <label htmlFor='cert-image' style={{marginBottom: '10px'}}>
                (png, jpg, jpeg files accepted. Max file size: 2MB)
              </label>
              {imageFeedback !== '' && (
                <Alert variant='danger' style={{marginBottom: '20px'}}>
                  {imageFeedback}
                </Alert>
              )}

              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button
                  type='button'
                  onClick={() => setShowModal(false)}
                  style={{
                    backgroundColor: '#f44336',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Close
                </button>
                <button
                  type='submit'
                  disabled={imageFeedback == '' ? false : true}
                  style={{
                    backgroundColor: `${imageFeedback == '' ? '#4CAF50' : '#bddebe'}`,
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: `${imageFeedback == '' ? 'pointer' : 'auto'}`,
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
