import {Routes, Route, Outlet, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Overview} from '../components/Overview'
import {ProfileHeader} from '../client_profile/ProfileHeader'
import {FC} from 'react'

interface ProfilePageLocationState {
  employeeQuery?: string
}

interface ProfilePageLocation {
  state?: ProfilePageLocationState
}

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/user-management/overall-employee/candidate-profile',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage_Ent: FC<{employeeQuery?: string}> = ({employeeQuery}) => {
  const location = useLocation()

  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <ProfileHeader
              employeeQuery={employeeQuery}
              basePath={profileBreadCrumbs[0].path + location.search}
            />
            <Outlet />
          </>
        }
      >
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Candidate Overview</PageTitle>
              {employeeQuery && <Overview employeeQuery={employeeQuery} />}
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ProfilePage_Ent
