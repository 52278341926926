import React, {useState, useEffect} from 'react'
import {DynamicSlider} from './DynamicSlider'
import {useUserContext} from '../../../../../UserContext'
import config from '../../../../../config.json'

// Interface definitions
interface TalentPoolControllerProps {
  className?: string
}

interface SliderItem {
  id: number
  value: number
  heading: string
  min: number
  max: number
  step: number
  subTitle: string
}

interface SliderData {
  [key: string]: number | string
}

const TalentPoolController: React.FC<TalentPoolControllerProps> = ({className}) => {
  const [talentPoolPercentage, setTalentPoolPercentage] = useState<number | undefined>(undefined) // Default value is set to undefined
  const {user} = useUserContext()
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertClassName, setAlertClassName] = useState<string>('alert-success') // Class name for the alert
  const [showSlider, setShowslider] = useState(false)

  async function callTalentPoolInfo() {
    if (user?.['user id']) {
      try {
        const ratioinfo = await fetch(`${config.domainName}:${config.port}/api/entityquery`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'AllEntityData',
            entity_id: user['user id'],
          }),
        })
        const ratioinfoCont: SliderData = await ratioinfo.json()
        if (ratioinfoCont && typeof ratioinfoCont['talent pool percentage'] === 'string') {
          const talentPoolPercentageValue: number = parseFloat(
            ratioinfoCont['talent pool percentage'] as string
          )
          const talentPoolPercentageVal: number = Math.round(talentPoolPercentageValue * 100)
          setTalentPoolPercentage(talentPoolPercentageVal)
        }
      } catch (error) {
        console.error('Error fetching talent pool info:', error)
      }
    }
  }

  async function updateTalentPoolInfo(talentPoolNum: string) {
    if (user?.['user id']) {
      try {
        const sendTalentPool = await fetch(`${config.domainName}:${config.port}/api/entityquery`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'SetTalentPool',
            entity_id: user['user id'],
            talent_pool: talentPoolNum,
          }),
        })

        const sendTalentPoolCont = await sendTalentPool.json()
        console.log(sendTalentPoolCont)

        // Update the alert message based on the API response
        setAlertMessage(sendTalentPoolCont.message || '')
        setAlertClassName(sendTalentPool.status !== null ? 'alert-success' : 'alert-danger')
      } catch (error) {
        console.error('Error updating talent pool info:', error)
        setAlertMessage('Error Updating Talent Pool')
        setAlertClassName('alert-danger')
      }
    } else {
      setAlertMessage('Error Updating Talent Pool')
      setAlertClassName('alert-danger')
    }
  }

  useEffect(() => {
    callTalentPoolInfo()
  }, [])

  const updatedInfo = (data: SliderItem[]) => {
    // Check if the value is greater than 1, then convert it to a percentage
    const talentPool = data[0].value > 1 ? data[0].value / 100 : data[0].value
    const talentString = talentPool.toString()
    updateTalentPoolInfo(talentString)
  }

  const toggleShowEditButton = () => {
    setShowslider((prevState) => !prevState)
  }

  return (
    <div className={`card ${className} h-100`}>
      {talentPoolPercentage !== undefined && (
        <>
          <DynamicSlider
            sliders={[
              {
                id: 1,
                value: talentPoolPercentage,
                heading: 'Talent Pool Percentage',
                min: 0,
                max: 100,
                step: 1,
                subTitle: '',
              } as SliderItem,
            ]}
            initialSliderValues={[talentPoolPercentage]}
            className='my-card'
            title='Talent Pool Controller'
            updateDataFunc={updatedInfo}
            alertMessage={alertMessage}
            alertClassName={alertClassName}
          />
        </>
      )}
    </div>
  )
}

export {TalentPoolController}
