import React, {FC} from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Font,
  Image,
  Svg,
} from '@react-pdf/renderer'
import {
  EmployeeAssessments,
  EmployeeDetails,
  TrainingCourse,
  Skill,
  PotentialSkillGroup,
} from '../../dataTypes'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import logo from '../../logo/default.png'
import tick from '../../logo/circle-check-primary-new.png'
import grades from '../../logo/gradesBarNew.png'
import {getCSS, getCSSVariableValue} from '../../../src/_metronic/assets/ts/_utils'
import ApexCharts, {ApexOptions} from 'apexcharts'

let defaultFont = 'MetricHPE-Desktop'
// let defaultFont = 'Inter'
const styles = StyleSheet.create({
  body: {
    paddingTop: 40,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: defaultFont,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  logo_center: {
    height: '65px',
    margin: 'auto',
  },
  logoLeft: {
    height: '35px',
    marginLeft: 5,
    marginTop: 5,
    justifyContent: 'flex-start',

    // margin: 'auto',
  },
  logoRight: {
    height: '35px',
    marginLeft: 5,
    marginTop: 5,
    justifyContent: 'flex-end',
    fontSize: '8px',

    // margin: 'auto',
  },
  heading: {
    // margin: 'auto',
    // fontFamily: 'Inter' || 'Helvetica',
    fontSize: 20,
    textAlign: 'center',
    paddingVertical: 5,
  },
  flatHeader: {
    width: '100%',
    backgroundColor: getCSSVariableValue('--bs-primary'),
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 700,
    paddingTop: defaultFont == 'Inter' ? 10 : 15,
    paddingBottom: 10,
    // padding: 10,
    marginTop: 10,
    fontSize: '18px',
    // fontFamily: 'Inter' || 'Helvetica',
  },
  flatSmallHeader: {
    width: '100%',
    backgroundColor: getCSSVariableValue('--bs-primary-active'),
    color: 'white',
    // textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 600,
    paddingTop: defaultFont == 'Inter' ? 10 : 15,
    paddingBottom: 10,
    // padding: 10,
    marginTop: 10,
    fontSize: '16px',
    // fontFamily: 'Inter' || 'Helvetica',
  },
  flatSmallerHeader: {
    width: '100%',
    backgroundColor: getCSSVariableValue('--bs-primary-light'),
    color: 'black',
    // textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 600,
    paddingTop: defaultFont == 'Inter' ? 10 : 15,
    paddingBottom: 10,
    // padding: 10,
    fontSize: '14px',
    // marginTop:10
    // fontFamily: 'Inter' || 'Helvetica',
  },
  profileHeader: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 10,
    // fontFamily: 'Inter' || 'Helvetica',
    // textOverflow: 'ellipsis',
    flex: 1,
    minHeight: 200,
  },
  profileHeaderCol2: {
    display: 'flex',
    flexDirection: 'column',
    paddingVertical: 10,
    fontSize: '10px',
    // fontFamily: 'Inter' || 'Helvetica',
    // textOverflow: 'ellipsis',
  },
  trainingTextBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingVertical: 10,
  },

  training_table_heading: {
    display: 'flex',
    flexDirection: 'row',
    borderColor: 'gray',
    borderWidth: '1px',
    backgroundColor: 'lightgray',
    width: '100%',
    margin: '0',
    marginTop: '10px',
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  training_table_heading_left: {
    display: 'flex',
    flexDirection: 'column',
    flex: '2.75',
    fontSize: '12',
    paddingLeft: '10px',
  },
  training_table_heading_right: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1.25',
    fontSize: '12',
    paddingLeft: '10px',
  },
  training_details: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: 'gray',
    borderWidth: '1px',
    margin: '0',
    marginTop: '-1px',
    marginBottom: '10px',
  },
  scoreCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: '#e7e9eb',
    borderWidth: 2,
    margin: '0',
    paddingHorizontal: 10,
    paddingVertical: 10,
    // marginTop: 10,
    marginBottom: 10,
  },

  training_table_left: {
    display: 'flex',
    flex: '2.75',
    borderRight: '1px',
    borderColor: 'gray',
    paddingLeft: '10px',
    paddingRight: '10px',
    // flexWrap: 'wrap',
    overflowWrap: 'normal',
    paddingTop: 3,
  },

  training_table_right: {
    display: 'flex',
    flex: '1.25',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: 3,
  },

  training_table_row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: '10',
    flexWrap: 'wrap',
    fontWeight: 200,
    // paddingTop: 2,
    // wordWrap: 'normal',
  },
  scoreTableLeft: {
    display: 'flex',
    flex: '2.5',
    // paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '10px',

    // flexWrap: 'wrap',
    overflowWrap: 'normal',
  },

  scoreTableRight: {
    display: 'flex',
    flex: '1.5',
    paddingLeft: '10px',
    paddingRight: '10px',
  },

  scoreTableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: '10px',
    flexWrap: 'wrap',
    // wordWrap: 'normal',
  },
  scoreTableRowBig: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: '14',
    flexWrap: 'wrap',
    // fontWeight: 800,
    // wordWrap: 'normal',
  },
  scoreTableLeftBig: {
    display: 'flex',
    flex: '2.5',
    // paddingLeft: '10px',
    paddingRight: '10px',
    // flexWrap: 'wrap',
    overflowWrap: 'normal',
    fontWeight: 600,
    // fontFamily: 'Inter' || 'Helvetica',
  },

  scoreTableRightBig: {
    display: 'flex',
    flex: '1.5',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontWeight: 600,
    // fontFamily: 'Inter' || 'Helvetica',
  },
  scoreTableRowHead: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: '14',
    flexWrap: 'wrap',
    paddingBottom: 5,
  },
  scoreTableLeftHead: {
    display: 'flex',
    flex: '2.5',
    paddingRight: '10px',
    overflowWrap: 'normal',
    fontWeight: 600,
    // fontFamily: 'Inter' || 'Helvetica',
  },

  scoreTableRightHead: {
    display: 'flex',
    flex: '1.5',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontWeight: 600,
    // fontFamily: 'Inter' || 'Helvetica',
  },
  employee_details: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px',
    paddingBottom: '5px',
    // fontSize: '100px',
    fontWeight: 200,
  },

  employee_tableMiddle: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    fontSize: '12',
  },

  employee_table_right: {
    display: 'flex',
    flexDirection: 'column',
    flex: '2',
    fontSize: '12',
  },
  employee_tableLeft: {
    display: 'flex',
    flexDirection: 'column',
    flex: '2',
    fontSize: '12',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    // paddingVertical: 10,
  },
  comp_table_grade_image: {
    height: '30px',
    float: 'right',
    transform: 'translate(0px,-15px)',
    alignContent: 'flex-start',
    padding: '2px',
  },
  comp_table_divider_line: {
    height: '2px',
    width: '92%',
    textAlign: 'center',
  },
  comp_table_text: {
    overflowWrap: 'normal',
    fontSize: '9px',
    fontWeight: 200,
    top: '-15',
  },
  comp_table_long: {
    width: '100%',
    borderColor: '#e7e9eb',
    borderWidth: '2px',
    margin: '10px',
    padding: '10px',
  },
  comp_table: {
    width: 240,
    height: 200,
    borderColor: '#e7e9eb',
    borderWidth: 2,
    margin: 10,
    padding: 10,
  },
  comp_table_divider: {
    display: 'flex',
    top: '12',
    flexDirection: 'row',
    width: '100%',
  },
  comp_table_header: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'left',
    maxWidth: '90%',
    top: '-32',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    // paddingBottom: 3,
    alignItems: 'center',
    width: '100%',
  },
})

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
})
{
}
try {
  Font.register({
    family: defaultFont,
    fonts: [
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-Thin.otf`),
        fontWeight: 100,
        fontStyle: 'normal',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-Light.otf`),
        fontWeight: 300,
        fontStyle: 'normal',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-Regular.otf`),
        fontWeight: 400,
        fontStyle: 'normal',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-Medium.otf`),
        fontWeight: 500,
        fontStyle: 'normal',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-Semibold.otf`),
        fontWeight: 600,
        fontStyle: 'normal',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-Bold.otf`),
        fontWeight: 700,
        fontStyle: 'normal',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-Black.otf`),
        fontWeight: 900,
        fontStyle: 'normal',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-ThinItalic.otf`),
        fontWeight: 100,
        fontStyle: 'italic',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-LightItalic.otf`),
        fontWeight: 300,
        fontStyle: 'italic',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-RegularItalic.otf`),
        fontWeight: 400,
        fontStyle: 'italic',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-MediumItalic.otf`),
        fontWeight: 500,
        fontStyle: 'italic',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-SemiboldItalic.otf`),
        fontWeight: 600,
        fontStyle: 'italic',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-BoldItalic.otf`),
        fontWeight: 700,
        fontStyle: 'italic',
      },
      {
        src: require(`../../_metronic/assets/sass/components/fonts/MetricHPE-Desktop-BlackItalic.otf`),
        fontWeight: 900,
        fontStyle: 'italic',
      },
    ],
  })
} catch (error) {
  // console.error('Font registration failed:', error)
  defaultFont = 'Helvetica'
}
try {
  Font.register({
    family: 'Inter',
    fonts: [
      {
        src: require(`../../fontPDF/Inter-Thin.ttf`),
        fontWeight: 100,
      },
      {
        src: require(`../../fontPDF/Inter-ExtraLight.ttf`),
        fontWeight: 200,
      },
      {
        src: require(`../../fontPDF/Inter-Light.ttf`),
        fontWeight: 300,
      },
      {
        src: require(`../../fontPDF/Inter-Regular.ttf`),
        fontWeight: 400,
      },
      {
        src: require(`../../fontPDF/Inter-Medium.ttf`),
        fontWeight: 500,
      },
      {
        src: require(`../../fontPDF/Inter-SemiBold.ttf`),
        fontWeight: 600,
      },
      {
        src: require(`../../fontPDF/Inter-Bold.ttf`),
        fontWeight: 700,
      },
      {
        src: require(`../../fontPDF/Inter-ExtraBold.ttf`),
        fontWeight: 800,
      },
      {
        src: require(`../../fontPDF/Inter-Black.ttf`),
        fontWeight: 900,
      },
    ],
  })
} catch (error) {
  // console.error('Font registration failed:', error)
  defaultFont = 'Helvetica'
}
type Employee = {
  name: string
  totalScore: number
  technicalScore: number
  potentialScore: number
  appraisalScore: number
  lmScore: number
  potentialRatio: number
  technicalRatio: number
  appraisalRatio: number
  lmRatio: number
  'employee id': string
}
interface EmpAppraisalInfo {
  appraisalRatio: string
  appraisalScore: number
  dateModified: string
}
type Props = {
  concise?: boolean
  empData?: EmployeeDetails
  aCourseTraining?: TrainingCourse[]
  cCourseTraining?: TrainingCourse[]
  fCourseTraining?: TrainingCourse[]
  empPfp?: string
  age?: number
  scoreEmpData?: Employee
  assessments?: EmployeeAssessments[]
  lineManager?: EmpLMInfo
  empKSA?: Skill[]
  potentialSkills?: PotentialSkillGroup[]
  appraisalInfo?: EmpAppraisalInfo
  completionPerc?: number
}
interface EmpLMInfo {
  managerName: string
  lmScore: number
  dateModified: string
  managerPosition: string
  managerEmpNum: string
}
const ReportPDF: FC<Props> = ({
  concise = false,
  empData,
  empPfp,
  age,
  aCourseTraining,
  cCourseTraining,
  fCourseTraining,
  scoreEmpData,
  assessments,
  lineManager,
  empKSA,
  potentialSkills,
  appraisalInfo,
  completionPerc,
}) => {
  function getBackgroundColor(grade: number) {
    if (grade >= 76) {
      return 'bg-proficient-grade'
    } else if (grade >= 51) {
      return 'bg-experienced-grade'
    } else if (grade >= 26) {
      return 'bg-intermediate-grade'
    } else {
      return 'bg-beginner-grade'
    }
  }

  const sortedEmpKSA = empKSA?.sort((a, b) => b.score - a.score)
  const sortedPotentialSkills = potentialSkills?.filter((pskill) =>
    pskill?.potentialskills.sort((a, b) => b.score - a.score)
  )

  const todayDate = new Date()

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.headerRow} fixed>
          <Image src={logo} style={styles.logoLeft} />
          <View style={{width: '80%'}}></View>
          <Text style={styles.logoRight}>Date: {todayDate.toLocaleDateString('en-GB')}</Text>
        </View>
        <View>{/* <Image src={logo} style={styles.logo_center} /> */}</View>
        <View style={styles.heading}>
          <Text style={{fontSize: '16px', fontWeight: 800}}>Talent Empower System Report</Text>
          <Text style={{fontSize: '14px', fontWeight: 600}}>
            {todayDate.toLocaleDateString('en-GB')}
          </Text>
        </View>
        <View style={styles.flatHeader}>
          <Text>Candidate Details</Text>
        </View>
        {/* this is there the profile header goes */}

        <View style={styles.employee_details}>
          <View style={styles.employee_tableLeft}>
            {/* profile picture */}

            {/* {empPfp && <Image style={{width: '65px', height: '65px'}} src={empPfp} />} */}

            {/* //********** */}
            <Text style={{fontWeight: 600, fontSize: '10px'}}>
              Name:{' '}
              <Text style={{fontWeight: 400, fontSize: '10px'}}>
                {empData?.name}{' '}
                {/* {empData?.['talent pool'] == 'true' && (
                  <Image style={{height: '10px'}} src={tick} />
                )}{' '} */}
              </Text>{' '}
            </Text>
            <Text style={{fontWeight: 600, fontSize: '10px'}}>
              Email: <Text style={{fontWeight: 400, fontSize: '10px'}}>{empData?.email}</Text>{' '}
            </Text>
            <Text style={{fontWeight: 600, fontSize: '10px'}}>
              Nationality: <Text style={{fontWeight: 400}}>{empData?.nationality}</Text>{' '}
            </Text>
          </View>
          <View style={styles.employee_tableMiddle}>
            <Text style={{fontWeight: 600, fontSize: '10px'}}>
              Age: <Text style={{fontWeight: 400, fontSize: '10px'}}>{age}</Text>
            </Text>
            <Text style={{fontWeight: 600, fontSize: '10px'}}>
              Gender: <Text style={{fontWeight: 400, fontSize: '10px'}}>{empData?.gender}</Text>
            </Text>
            <Text style={{fontWeight: 600, fontSize: '10px'}}>
              Entity: <Text style={{fontWeight: 400, fontSize: '10px'}}>{empData?.entity}</Text>
            </Text>
          </View>
          <View style={styles.employee_table_right}>
            <Text style={{fontWeight: 600, fontSize: '10px'}}>
              Job Title:{' '}
              <Text style={{fontWeight: 400, fontSize: '10px'}}>{empData?.['job title']}</Text>
            </Text>
            <Text style={{fontWeight: 600, fontSize: '10px'}}>
              Reporting to:{' '}
              <Text style={{fontWeight: 400, fontSize: '10px'}}>
                {empData?.['role reporting to']}
              </Text>
            </Text>
            <Text style={{fontWeight: 600, fontSize: '10px'}}>
              Reportees: <Text style={{fontWeight: 400}}>{empData?.['reporting to you']}</Text>{' '}
            </Text>

            {/* <Text>Score: {Number(empData?.['employee score']) * 100}</Text> */}
          </View>
        </View>
        <View style={styles.flatHeader}>
          <Text>Overview</Text>
        </View>
        {scoreEmpData != null && (
          <View style={{...styles.scoreCardContainer, marginTop: '10px'}}>
            <Text style={{fontWeight: 600, fontSize: '14px', paddingBottom: '5px'}}>
              Score Breakdown Table
            </Text>
            {scoreEmpData?.technicalRatio != 0 && (
              <View style={styles.scoreTableRow}>
                <Text style={styles.scoreTableLeft}>Performance (Technical)</Text>
                <Text style={styles.scoreTableRight}>
                  {Math.round(scoreEmpData?.technicalScore * scoreEmpData?.technicalRatio)}
                  {' / '}
                  {Math.round(scoreEmpData?.technicalRatio)}
                </Text>
              </View>
            )}
            {scoreEmpData?.appraisalRatio != 0 && (
              <View style={styles.scoreTableRow}>
                <Text style={styles.scoreTableLeft}>Appraisal</Text>
                <Text style={styles.scoreTableRight}>
                  {Math.round(scoreEmpData?.appraisalScore * scoreEmpData?.appraisalRatio)}
                  {' / '}
                  {Math.round(scoreEmpData?.appraisalRatio)}
                </Text>
              </View>
            )}

            {scoreEmpData?.lmRatio != 0 && (
              <View style={styles.scoreTableRow}>
                <Text style={styles.scoreTableLeft}>Line Manager</Text>
                <Text style={styles.scoreTableRight}>
                  {Math.round(scoreEmpData?.lmScore * scoreEmpData?.lmRatio)}
                  {' / '}
                  {Math.round(scoreEmpData?.lmRatio)}
                </Text>
              </View>
            )}
            {scoreEmpData?.potentialRatio != 0 && (
              <View style={styles.scoreTableRow}>
                <Text style={styles.scoreTableLeft}>Potential</Text>
                <Text style={styles.scoreTableRight}>
                  {Math.round(scoreEmpData?.potentialScore * scoreEmpData?.potentialRatio)}
                  {' / '}
                  {Math.round(scoreEmpData?.potentialRatio)}
                </Text>
              </View>
            )}
            <View style={styles.scoreTableRowBig}>
              <Text style={styles.scoreTableLeftBig}>Total Score</Text>
              <Text style={styles.scoreTableRightBig}>
                {Math.round(scoreEmpData?.totalScore * 100)}%
              </Text>
            </View>
          </View>
        )}

        {/* assessments overview  */}
        {/* <View style={styles.scoreCardContainer}>
          {assessments != null && completionPerc != null && (
            <Text>Assessment Completion: {completionPerc?.toFixed(0)}% </Text>
          )}
          <View style={styles.training_table_heading}>
            <View style={styles.training_table_heading_left}>
              <Text>Assessment</Text>
            </View>
            <View style={styles.training_table_heading_right}>
              <Text>Deadline</Text>
            </View>
            <View style={styles.training_table_heading_right}>
              <Text>Status</Text>
            </View>
          </View>
          {assessments?.map((ass) => (
            <View style={styles.training_table_row}>
              <Text style={styles.training_table_left}>{ass.name}</Text>
              <Text style={styles.training_table_right}>
                {new Date(ass['expiry date']).toLocaleDateString('en-GB')}
              </Text>
              <Text style={styles.training_table_right}>{ass.status}</Text>
            </View>
          ))}
        </View> */}
        {/* performance technical  */}
        {scoreEmpData?.technicalRatio != 0 && empKSA && (
          <View style={styles.scoreCardContainer}>
            <View style={styles.scoreTableRowHead}>
              <Text style={styles.scoreTableLeftHead}>Performance (Technical)</Text>
              <Text style={styles.scoreTableRightHead}>
                Score:{' '}
                {Math.round(
                  (empKSA.reduce((accumulator, object) => {
                    return accumulator + object.score
                  }, 0) /
                    empKSA.length) *
                    100
                )}
                %
              </Text>
            </View>
            {sortedEmpKSA?.map((ksa) => (
              <View style={styles.scoreTableRow}>
                <Text style={styles.scoreTableLeft}>{ksa.title}</Text>
                <Text style={styles.scoreTableRight}>
                  {' '}
                  <Text style={{color: ksa.color}}>{Math.round(ksa.score * 100)}%</Text>
                </Text>
              </View>
            ))}
          </View>
        )}
        {/* appraisal info  */}
        {scoreEmpData?.appraisalRatio != 0 && (
          <View style={styles.scoreCardContainer}>
            <View style={styles.scoreTableRowHead}>
              <Text style={styles.scoreTableLeftHead}>Performance (Appraisal)</Text>
              <Text style={styles.scoreTableRightHead}>
                Score: {((appraisalInfo?.appraisalScore as number) * 100).toFixed(0)}%
              </Text>
            </View>
            <View style={styles.scoreTableRow}>
              <Text style={styles.scoreTableLeft}>Last Updated</Text>
              <Text style={styles.scoreTableRight}>
                {new Date(appraisalInfo?.dateModified as string).toLocaleDateString('en-GB')}
              </Text>
            </View>
          </View>
        )}

        {/* line manager overview  */}
        {scoreEmpData?.lmRatio != 0 && (
          <View style={styles.scoreCardContainer}>
            <View style={styles.scoreTableRowHead}>
              <Text style={styles.scoreTableLeftHead}>Line Manager</Text>
              <Text style={styles.scoreTableRightHead}>
                Score: {Math.round((lineManager?.lmScore as number) * 100)}%
              </Text>
            </View>
            <View style={styles.scoreTableRow}>
              <Text style={styles.scoreTableLeft}>Last Updated</Text>
              <Text style={styles.scoreTableRight}>
                {new Date(lineManager?.dateModified as string).toLocaleDateString('en-GB')}
              </Text>
            </View>
            <View style={styles.scoreTableRow}>
              <Text style={styles.scoreTableLeft}>Updated By</Text>
              <Text style={styles.scoreTableRight}>{lineManager?.managerName}</Text>
            </View>
            <View style={styles.scoreTableRow}>
              <Text style={styles.scoreTableLeft}>Position</Text>
              <Text style={styles.scoreTableRight}>{lineManager?.managerPosition}</Text>
            </View>
            <View style={styles.scoreTableRow}>
              <Text style={styles.scoreTableLeft}>Employee Number</Text>
              <Text style={styles.scoreTableRight}>{lineManager?.managerEmpNum}</Text>
            </View>
          </View>
        )}
        <Text
          style={styles.pageNumber}
          render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
      {potentialSkills && empData?.['potential score'] != '0' && scoreEmpData?.potentialRatio != 0 && (
        <Page style={styles.body}>
          <View style={styles.headerRow} fixed>
            <Image src={logo} style={styles.logoLeft} />
            <View style={{width: '80%'}}></View>
            <Text style={styles.logoRight}>Date: {todayDate.toLocaleDateString('en-GB')}</Text>
          </View>
          {/* potential info  */}

          <View>
            <View style={{...styles.scoreTableRowHead, paddingTop: '10px', fontSize: '15px'}}>
              <Text style={styles.scoreTableLeftHead}>Potential</Text>
              <Text style={styles.scoreTableRightHead}>
                Score: {Math.round((scoreEmpData?.potentialScore as number) * 100)}%
              </Text>
            </View>
            {potentialSkills.map((pSKill) => (
              <>
                <View style={styles.scoreCardContainer}>
                  <View style={styles.scoreTableRowHead}>
                    <Text style={styles.scoreTableLeftHead}>{pSKill.group}</Text>
                    <Text style={styles.scoreTableRightHead}>
                      Score:{' '}
                      {Math.round(
                        (pSKill.potentialskills.reduce((accumulator, object) => {
                          return accumulator + object.score
                        }, 0) /
                          pSKill.potentialskills.length) *
                          100
                      )}
                      %
                    </Text>
                  </View>
                  <View>
                    {pSKill.potentialskills.map((skill) => (
                      <View style={styles.scoreTableRow}>
                        <Text style={styles.scoreTableLeft}>{skill.title}</Text>
                        <Text style={styles.scoreTableRight}>
                          {' '}
                          <Text style={{color: skill.color}}>{Math.round(skill.score * 100)}%</Text>
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              </>
            ))}
          </View>

          <Text
            style={styles.pageNumber}
            render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
            fixed
          />
        </Page>
      )}
      {concise == false && (
        <>
          <Page style={styles.body}>
            <View style={styles.headerRow} fixed>
              <Image src={logo} style={styles.logoLeft} />
              <View style={{width: '80%'}}></View>
              <Text style={styles.logoRight}>Date: {todayDate.toLocaleDateString('en-GB')}</Text>
            </View>
            <View style={styles.flatHeader}>
              <Text>Detailed Competencies</Text>
            </View>
            <Text style={{fontSize: '14px', paddingVertical: '10px'}}>
              This section illustrates the cybersecurity competencies level that have been assessed.
              Each box represents one competency and the needed areas of improvements in Knowledge,
              Skills or Abilities.
            </Text>

            <View style={{height: '65px', marginVertical: '20px'}}>
              <Image style={styles.logo_center} src={grades} />
            </View>
            {scoreEmpData?.technicalRatio != 0 && (
              <>
                <View style={styles.flatSmallHeader}>
                  <Text>Performance (Technical)</Text>
                </View>
                <View style={styles.cardContainer}>
                  {empKSA &&
                    empKSA.map((ksa, index) => (
                      <View key={index} style={styles.comp_table} wrap={false}>
                        <View style={styles.comp_table_divider}>
                          <View
                            style={{
                              ...styles.comp_table_divider_line,
                              backgroundColor: ksa['color'],
                            }}
                          />
                          <Image
                            style={styles.comp_table_grade_image}
                            src={require(`../../grade-icons/grade-${ksa['grade']}-filled.png`)}
                          />
                        </View>
                        <Text style={styles.comp_table_header} wrap={false}>
                          {ksa['title']}
                        </Text>
                        <Text style={styles.comp_table_text} wrap={false}>
                          {ksa['description']}
                        </Text>
                      </View>
                    ))}
                </View>
              </>
            )}
            {scoreEmpData?.potentialRatio != 0 && scoreEmpData?.technicalRatio == 0 && (
              <>
                {/* <View style={styles.headerRow} fixed>
                  <Image src={logo} style={styles.logoLeft} />
                  <View style={{width: '80%'}}></View>
                  <Text style={styles.logoRight}>
                    Date: {todayDate.toLocaleDateString('en-GB')}
                  </Text>
                </View> */}
                {/* <View style={{}}></View> */}
                <View style={{...styles.flatSmallHeader, marginTop: '10px'}}>
                  <Text>Potential Competencies</Text>
                </View>
                <>
                  {potentialSkills && empData?.['potential score'] != '0' && (
                    <>
                      {potentialSkills.map((pSKill, index) => (
                        <>
                          <View style={styles.flatSmallerHeader}>
                            <Text>{pSKill.group}</Text>
                          </View>
                          <View style={styles.cardContainer}>
                            {pSKill.potentialskills.map((ksa, index) => (
                              <View
                                key={index}
                                style={{...styles.comp_table, marginBottom: '20px'}}
                                wrap={false}
                              >
                                <View style={styles.comp_table_divider}>
                                  <View
                                    style={{
                                      ...styles.comp_table_divider_line,
                                      backgroundColor: ksa['color'],
                                    }}
                                  />
                                  <Image
                                    style={styles.comp_table_grade_image}
                                    src={require(`../../grade-icons/grade-${ksa['grade']}-filled.png`)}
                                  />
                                </View>
                                <Text style={styles.comp_table_header} wrap={false}>
                                  {ksa['title']}
                                </Text>
                                <Text style={styles.comp_table_text} wrap={false}>
                                  {ksa['description']}
                                </Text>
                              </View>
                            ))}
                          </View>
                        </>
                      ))}
                    </>
                  )}
                </>
              </>
            )}
            <Text
              style={styles.pageNumber}
              render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
          {scoreEmpData?.potentialRatio != 0 && scoreEmpData?.technicalRatio != 0 && (
            <Page style={styles.body}>
              <View style={styles.headerRow} fixed>
                <Image src={logo} style={styles.logoLeft} />
                <View style={{width: '80%'}}></View>
                <Text style={styles.logoRight}>Date: {todayDate.toLocaleDateString('en-GB')}</Text>
              </View>
              <View style={{...styles.flatSmallHeader, marginBottom: '5px'}}>
                <Text>Potential Competencies</Text>
              </View>
              <>
                {potentialSkills && empData?.['potential score'] != '0' && (
                  <>
                    {potentialSkills.map((pSKill, index) => (
                      <>
                        <View style={styles.flatSmallerHeader}>
                          <Text>{pSKill.group}</Text>
                        </View>
                        <View style={styles.cardContainer}>
                          {pSKill.potentialskills.map((ksa, index) => (
                            <View key={index} style={styles.comp_table} wrap={false}>
                              <View style={styles.comp_table_divider}>
                                <View
                                  style={{
                                    ...styles.comp_table_divider_line,
                                    backgroundColor: ksa['color'],
                                  }}
                                />
                                <Image
                                  style={styles.comp_table_grade_image}
                                  src={require(`../../grade-icons/grade-${ksa['grade']}-filled.png`)}
                                />
                              </View>
                              <Text style={styles.comp_table_header} wrap={false}>
                                {ksa['title']}
                              </Text>
                              <Text style={styles.comp_table_text} wrap={false}>
                                {ksa['description']}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </>
                    ))}
                  </>
                )}
              </>
              <Text
                style={styles.pageNumber}
                render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
                fixed
              />
            </Page>
          )}
        </>
      )}

      {/* training page */}
      <Page style={styles.body}>
        <View style={styles.headerRow} fixed>
          <Image src={logo} style={styles.logoLeft} />
          <View style={{width: '80%'}}></View>
          <Text style={styles.logoRight}>Date: {todayDate.toLocaleDateString('en-GB')}</Text>
        </View>
        {/* training data */}
        <View>
          <View style={styles.flatHeader}>
            <Text>Recommended Trainings</Text>
          </View>
          <View style={styles.trainingTextBox}>
            <Text style={{fontSize: '14px'}}>
              This section illustrates the cybersecurity competencies level that have been assessed.
              Each box represents one competency and the needed areas of improvements in Knowledge,
              Skills or Abilities.
            </Text>
          </View>
          <View style={styles.flatSmallHeader}>
            <Text>Foundation Training Courses</Text>
          </View>
          {fCourseTraining?.length != 0 ? (
            <>
              <View style={styles.training_table_heading}>
                <View style={styles.training_table_heading_left}>
                  <Text>Course Name</Text>
                </View>
                <View style={styles.training_table_heading_right}>
                  <Text>Type</Text>
                </View>
              </View>
              <View style={styles.training_details}>
                {fCourseTraining &&
                  fCourseTraining?.map((fCourse) => (
                    <View style={styles.training_table_row}>
                      <Text style={styles.training_table_left}>{fCourse['course name']}</Text>
                      <Text style={styles.training_table_right}>{fCourse['course type']}</Text>
                    </View>
                  ))}
                <View style={styles.training_table_row}></View>
              </View>
            </>
          ) : (
            <View>
              <Text style={{paddingVertical: '10px', fontSize: '12px'}}>
                There are no assigned trainings for this user in this category
              </Text>
            </View>
          )}

          <View style={styles.flatSmallHeader}>
            <Text>Core Training Courses</Text>
          </View>
          {cCourseTraining?.length != 0 ? (
            <>
              <View style={styles.training_table_heading}>
                <View style={styles.training_table_heading_left}>
                  <Text>Course Name</Text>
                </View>
                <View style={styles.training_table_heading_right}>
                  <Text>Type</Text>
                </View>
              </View>
              <View style={styles.training_details}>
                {cCourseTraining &&
                  cCourseTraining?.map((cCourse) => (
                    <View style={styles.training_table_row}>
                      <Text style={styles.training_table_left}>{cCourse['course name']}</Text>
                      <Text style={styles.training_table_right}>{cCourse['course type']}</Text>
                    </View>
                  ))}
                <View style={styles.training_table_row}></View>
              </View>
            </>
          ) : (
            <View>
              <Text style={{paddingVertical: '10px', fontSize: '12px'}}>
                There are no assigned trainings for this user in this category
              </Text>
            </View>
          )}

          <View style={styles.flatSmallHeader}>
            <Text>Advanced Training Courses</Text>
          </View>
          {aCourseTraining?.length != 0 ? (
            <>
              <View style={styles.training_table_heading}>
                <View style={styles.training_table_heading_left}>
                  <Text>Course Name</Text>
                </View>
                <View style={styles.training_table_heading_right}>
                  <Text>Type</Text>
                </View>
              </View>
              <View style={styles.training_details}>
                {aCourseTraining?.length != 0 &&
                  aCourseTraining?.map((aCourse) => (
                    <View style={styles.training_table_row}>
                      <Text style={styles.training_table_left}>{aCourse['course name']}</Text>
                      <Text style={styles.training_table_right}>{aCourse['course type']}</Text>
                    </View>
                  ))}
                <View style={styles.training_table_row}></View>
              </View>
            </>
          ) : (
            <View>
              <Text style={{paddingVertical: '10px', fontSize: '12px'}}>
                There are no assigned trainings for this user in this category
              </Text>
            </View>
          )}
        </View>

        <Text
          style={styles.pageNumber}
          render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )
}

export default ReportPDF
