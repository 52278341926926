import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {GradesBreakdown} from '../../_metronic/partials/widgets/lists/GradesBreakdown'
import {EntityPendingInfoDonut} from '../../_metronic/partials/widgets/_new/cards/EntityPendingInfo'
import {OverallEntGrade} from '../../_metronic/partials/widgets/_new/cards/OverallEntGrade'
import {useUserContext} from '../../UserContext'
import config from '../../config.json'
import {OverallAssessments} from '../../_metronic/partials/widgets/_new/cards/OverallAssessments'
import {DepartmentsTable, GradesBreakdownTable} from '../../_metronic/partials/widgets'
import {EntAssessmentInfoTable} from '../../_metronic/partials/widgets/tables/EntAssessmentsInfoTable'
import {EntityTalentInfo} from '../../_metronic/partials/widgets/_new/cards/EntityTalentInfo'
import {TalentInfoTable} from '../../_metronic/partials/widgets/tables/TalentInfoTable'

const DashboardPage: FC = (props) => {
  return (
    <div>
      <div className='row g-2 mb-5' style={{height: '560px'}}>
        <div className='col-9 h-100'>
          <div className='row g-2 mb-5' style={{height: '150px'}}>
            <div className='col-4 h-100'>
              <OverallEntGrade
                className='h-md-3 h-100'
                title='Average Grade'
                chartSize={50}
                chartLine={8}
                chartRotate={0}
              />
            </div>
            <div className='col-4 h-100'>
              <EntityPendingInfoDonut
                className='h-md-3 h-100'
                subtitle=''
                chartSize={50}
                chartLine={8}
                chartRotate={0}
                color='white'
              />
            </div>
            <div className='col-4 h-100'>
              <OverallAssessments className='h-md-3 h-100' title='Overall Assessments' />
            </div>
          </div>
          <div className='row g-2 mb-5' style={{height: '400px'}}>
            <div className='col-8 h-100'>
              <GradesBreakdownTable className='h-md-3 h-100 ' title='Score Weight Breakdown' />
            </div>
            <div className='col-4 g-2 h-100'>
              <EntityTalentInfo className='h-md-3 h-100' subtitle='' color='white' />
            </div>
          </div>
        </div>
        <div className='col-3 h-100'>
          <GradesBreakdown title='Grades Breakdown' className='h-100' />
        </div>
      </div>
      <div className='row g-2 mb-5' style={{height: '400px'}}>
        <div className='col-4 h-100'>
          <DepartmentsTable className='h-md-3 h-100' title='Department Breakdown' />
        </div>
        <div className='col-4 h-100'>
          <EntAssessmentInfoTable className='card card-xl-stretch ' title='Assessment Breakdown' />
        </div>
        <div className='col-4 h-100'>
          <TalentInfoTable className='card card-xl-stretch ' title='Talent Pool' />
        </div>
      </div>
    </div>
  )
}

const EntDashboard: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ENTITY.DASHBOARD'})}</PageTitle>
      {<DashboardPage />}
    </>
  )
}

export {EntDashboard}
