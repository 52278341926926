import {Navigate, Routes, Route, Outlet, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Overview} from '../components/Overview'
import {PerformanceTechnical} from '../components/employee_components/Performance(Technical)'
import {Certification} from '../components/employee_components/Certification'
import {PotentialSkills} from '../components/employee_components/Potenital'
import {Assesments} from '../components/employee_components/Assesments'
import {Education} from '../components/employee_components/Education'
import {ProfileHeader} from './ProfileHeader'
import {TrainingPaths} from '../components/employee_components/TrainingPaths'
import {useState, FC} from 'react'
import {Testing} from '../components/employee_components/Testing'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/profile/overview',
    isSeparator: false,
    isActive: false,
  },
]

const ProfilePage: FC = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const employeeQueryFromSearch = searchParams.get('employeeQuery')
  const [employeeQuery, setEmployeeQuery] = useState<string | null>(employeeQueryFromSearch)

  return (
    <Routes>
      <Route
        element={
          <>
            {employeeQuery ? <ProfileHeader employeeQuery={employeeQuery} /> : <ProfileHeader />}
            <Outlet />
          </>
        }
      >
        <Route
          path='Overview'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Candidate Overview</PageTitle>
              <Overview />
            </>
          }
        />
        {!employeeQuery && (
          <>
            <Route
              path='performance'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    Performance (Techincal) Results
                  </PageTitle>
                  <PerformanceTechnical />
                </>
              }
            />

            <Route
              path='potential'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Potential Results</PageTitle>
                  <PotentialSkills />
                </>
              }
            />

            <Route
              path='certification'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Certification</PageTitle>
                  <Certification />
                </>
              }
            />

            <Route
              path='education'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Education</PageTitle>
                  <Education />
                </>
              }
            />

            <Route
              path='assessments'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Assigned Assesments</PageTitle>
                  {employeeQuery ? <Assesments employeeQuery={employeeQuery} /> : <Assesments />}
                </>
              }
            />

            <Route
              path='training-Paths'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Training Path</PageTitle>
                  <TrainingPaths />
                </>
              }
            />
            <Route
              path='testing-page'
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>Testing Page</PageTitle>
                  <Testing />
                </>
              }
            />
          </>
        )}
        <Route index element={<Navigate to='/profile/overview' />} />
      </Route>
    </Routes>
  )
}

export default ProfilePage
