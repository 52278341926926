/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, FC} from 'react'
import config from '../../../../../config.json'
import {EmployeeAssessments} from '../../../../../dataTypes'
import {AssesmentsTable} from '../../../../../_metronic/partials/widgets'
import {useUserContext} from '../../../../../UserContext'
import {useCallInterval} from '../../../../../CallTimeIntervalContext'
export const Assesments: FC<{className?: string; isCompact?: boolean; employeeQuery?: string}> = ({
  className = '',
  employeeQuery,
  isCompact = false,
}) => {
  const [employeeAssessments, setEmployeeAssessments] = useState<EmployeeAssessments[]>([])
  const {user} = useUserContext()
  const {callInterval} = useCallInterval()

  const [completionPercentage, setCompletionPercentage] = useState(0)
  const [showLoading, setShowLoading] = useState(false) // State to control showing the warning message

  const warningTime = 5000

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  async function callAssessments(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const getAssessments = await fetch(
        config.domainName + ':' + config.port + '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetEmployeeAssessments',
            employee_id: employeeQuery,
          }),
        }
      )

      const getAssessmentsCont = await getAssessments.json()
      const assessmentsArray: EmployeeAssessments[] = getAssessmentsCont['all assessments'].map(
        (assessment: any) => ({
          'employee assessment id': assessment['employee assessment id'],
          'employee id': assessment['employee id'],
          name: assessment['name'],
          link: assessment['link'],
          'invite id': assessment['invite id'],
          'creation date': assessment['creation date'],
          'expiry date': assessment['expiry date'],
          status: assessment['status'],
        })
      )
      setEmployeeAssessments(assessmentsArray)
      setCompletionPercentage(
        (assessmentsArray.filter((assessment) => assessment.status === 'Complete').length /
          assessmentsArray.length) *
          100
      )
    }
  }

  useEffect(() => {
    if (employeeQuery) {
      callAssessments(employeeQuery)
    } else {
      callAssessments(user?.['user id'])
    }
  }, [employeeQuery])

  useEffect(() => {
    const interval = setInterval(() => {
      if (employeeQuery) {
        callAssessments(employeeQuery)
      } else {
        callAssessments(user?.['user id'])
      }
    }, callInterval)

    return () => clearInterval(interval)
  }, [employeeQuery])

  return (
    <div className={className}>
      {employeeAssessments.length !== 0 ? (
        <AssesmentsTable
          className={`mb-5 mb-xxl-8 h-100`}
          employeeAssessments={employeeAssessments}
          completionPercentage={completionPercentage}
          isCompact={isCompact}
          employeeQuery={employeeQuery ? employeeQuery : ''}
        />
      ) : showLoading ? (
        <div className='d-flex flex-row'>
          <div style={{height: '100%', width: '100%'}}>
            <div className={`card  mb-4 mb-xxl-8 h-100`}>
              <div className='card-header border-0 pt-5 '>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Assessments Progress</span>
                </h3>
              </div>

              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <div className='d-flex justify-content-center m-5 fw-bold fs-4'>
                    There are no assessments currently assigned. Please check back later!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-row h-100 w-100'>
          <div className={`card  mb-4 mb-xxl-8 h-100 w-100`}>
            <div className='card-header border-0 pt-5 '>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Assessments Progress</span>
              </h3>
            </div>

            <div className='card-body h-100 w-100  pb-4 d-flex justify-content-center align-items-center flex-column-fluid '>
              <span
                className='spinner-border spinner-border-lg spinner-info'
                style={{
                  width: '100px',
                  height: '100px',
                  border: ' var(--bs-spinner-border-width) solid #01a982',
                  borderRightColor: 'var(--bs-light)',
                  borderWidth: '10px',
                }}
              ></span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
