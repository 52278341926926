/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import WarningSpinner from '../../../../app/modules/profile/components/WarningSpinner'
import {TableWithScore} from './TableWithScore'
import {useCallInterval} from '../../../../CallTimeIntervalContext'

type Props = {
  className: string
  title?: string
}

const EntAssessmentInfoTable: React.FC<Props> = ({className, title}) => {
  const [entityGradeInfo, setEntityGradeInfo] = useState<string[][]>()

  const {user} = useUserContext()
  const {callInterval} = useCallInterval()

  async function callEntGradeInfo() {
    if (user?.['user id']) {
      const gradeInfo = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'EntityOverallAssessmentInfo',
          entity_id: user['user id'],
        }),
      })
      const gradeInfoCont = await gradeInfo.json()

      setEntityGradeInfo(gradeInfoCont['overall assessment information'])
    }
  }

  useEffect(() => {
    callEntGradeInfo()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      callEntGradeInfo()
    }, callInterval)

    return () => clearInterval(interval)
  }, [])

  const handleRefresh = () => {
    callEntGradeInfo()
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header flex-row border-0 pt-5'>
        <h3 className='card-title align-items-start me-auto'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>
      <div className='card-body py-3' style={{maxHeight: '500px', overflow: 'auto'}}>
        {entityGradeInfo ? (
          <TableWithScore tableData={entityGradeInfo} />
        ) : (
          <WarningSpinner spinnerWidth='100px' />
        )}
      </div>
    </div>
  )
}

export {EntAssessmentInfoTable}
