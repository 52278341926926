/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import config from '../../config.json'
import {UserModel} from '../../dataTypes'

type Props = {
  fetchedLogin: boolean
  setLoggedIn: any
  setUser: any
}

const loginSchema = Yup.object().shape({
  email: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

const Login: FC<Props> = ({fetchedLogin, setLoggedIn, setUser}) => {
  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [isValidPass, setIsValidPass] = useState(true)
  const [isValidUser, setIsValidUser] = useState(true)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const response = await fetch(config.domainName + ':' + config.port + '/api/login', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          credentials: 'include',
          body: JSON.stringify({
            name: values.email,
            password: values.password,
          }),
        })

        const content = await response.json()

        if (content.message === 'Incorrect password entered') {
          setStatus('Incorrect Password. Please try again!')
          setFeedback('Incorrect Password. Please try again!')
          setIsValidPass(false)
          setIsValidUser(true)
          setLoading(false)
        } else if (content.message === 'User not found in the database!') {
          setStatus('User does not exist. Please enter a valid username!')
          setFeedback('User does not exist. Please enter a valid username!')
          setIsValidUser(false)
          setIsValidPass(true)
          setLoading(false)
        } else if (content.message === 'success') {
          setIsValidPass(true)
          setIsValidUser(true)
          setLoggedIn(true)

          const userdata: UserModel = {
            'user id': content['user id'],
            'user name': content['user name'],
            'user type': content['user type'],
          }

          setUser(userdata)
          setLoading(false)

          // Redirect to the dashboard
        } else {
          setStatus(
            'Server connection failed! Please try again later.\nIf the issue persists please contact the site administrator.'
          )
          setFeedback(
            'Server connection failed! Please try again later.\nIf the issue persists please contact the site administrator.'
          )
          setLoading(false)
        }
      } catch (error) {
        console.error('Fetch the error indeed:', error)
        setStatus(
          'Server connection failed! Please try again later.\nIf the issue persists please contact the site administrator.'
        )
        setFeedback(
          'Server connection failed! Please try again later.\nIf the issue persists please contact the site administrator.'
        )
        setLoading(false)
      } finally {
        setTimeout(() => {
          setFeedback('')
        }, 3500)
        setSubmitting(false)
      }
    },
  })

  const {
    getFieldProps,
    touched: formikTouched,
    errors: formikErrors,
    isSubmitting,
    isValid,
  } = formik

  const {email, password} = formikTouched
  const {email: emailError, password: passwordError} = formikErrors
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={
        {
          // backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        }
      }
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.svg')} className='h-45px' />
        </a>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='text-center mb-11'>
              <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
            </div>

            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
              <input
                placeholder='Username'
                {...getFieldProps('email')}
                className={clsx('form-control bg-transparent', {
                  'is-invalid': email && !isValidUser,
                })}
                type='text'
                name='email'
                autoComplete='off'
              />
            </div>

            <div className='fv-row mb-3'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              <input
                type='password'
                autoComplete='off'
                {...getFieldProps('password')}
                className={clsx('form-control bg-transparent', {
                  'is-invalid': !isValidPass,
                })}
              />
              {password && passwordError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{passwordError}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
              <div />
            </div>
            {feedback && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{feedback}</div>
              </div>
            )}
            <div className='d-grid mb-10'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={isSubmitting || !isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {Login}
