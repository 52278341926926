import React, {useState, useEffect} from 'react'
import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import WarningSpinner from '../../../../app/modules/profile/components/WarningSpinner'
import {TableWithScore} from './TableWithScore'
import {Link} from 'react-router-dom'
import {GradeRatioCard} from '../_new/cards/GradeRatioController'

type Props = {
  className: string
  title?: string
}

const GradesBreakdownTable: React.FC<Props> = ({className, title}) => {
  const [entityGradeInfo, setEntityGradeInfo] = useState<string[][]>()
  const [entityGradeInfoContainer, setEntityGradeInfoContainer] = useState<string[][]>()
  const [showGradeBreakdownController, setShowGradeBreakdownController] = useState(false)

  const {user} = useUserContext()

  async function callEntGradeInfo() {
    if (user?.['user id']) {
      const gradeInfo = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'EntityGradeRatio',
          entity_id: user['user id'],
        }),
      })
      const gradeInfoCont = await gradeInfo.json()
      setEntityGradeInfo(gradeInfoCont['entity score'])
      const entityGradeInfoCont = gradeInfoCont['entity score'].filter((v: any) => {
        return v['Ratio'] !== 0
      })

      setEntityGradeInfoContainer(entityGradeInfoCont)
    }
    console.log('Data has been refreshed.')
  }

  useEffect(() => {
    callEntGradeInfo()
  }, [showGradeBreakdownController]) // Add the state as a dependency

  const toggleShowEditButton = () => {
    setShowGradeBreakdownController((prevState) => !prevState)
  }

  return (
    <div className={`card ${className}`}>
      {/* Show the icon based on the condition */}
      {user?.['user type'] === 'entity-editor' && (
        <i
          className={
            showGradeBreakdownController
              ? 'fa-solid fa-xmark text-hover-dark fs-2'
              : 'fa-solid fa-pen-to-square text-hover-dark fs-2'
          }
          onClick={toggleShowEditButton}
          style={{
            position: 'absolute',
            top: '8%',
            right: '10%',
            zIndex: 1, // Higher z-index to place it on top
          }}
        ></i>
      )}

      {showGradeBreakdownController ? (
        <GradeRatioCard sliders={[]} />
      ) : (
        <div className={`card card-flush bgi-no-repeat bgi-size-contain  ${className}`}>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <Link to={'/candidate-overview?extendScore=true'}>
                <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
              </Link>
            </h3>
          </div>
          <div className='card-body py-3 ' style={{maxHeight: '500px', overflow: 'auto'}}>
            {entityGradeInfoContainer ? (
              <TableWithScore tableData={entityGradeInfoContainer as any[]} sortingColumn={1} />
            ) : (
              <WarningSpinner canReload={true} reloadFunc={callEntGradeInfo} />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export {GradesBreakdownTable}
