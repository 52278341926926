import {lazy, FC, Suspense, useState, useEffect, useCallback} from 'react'
import {Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {Login} from '../pages/Login'
import {useUserContext} from '../../UserContext'
import {EmployeeProfile} from '../modules/profile/entity_profile/EmployeeProfile_Ent'
import {EmployeeCompetencies} from '../modules/profile/entity_profile/EmployeeCompetencies_Ent'
import ProfilePageTestPaths_Ent from '../modules/profile/entity_profile/ProfilePageTraining_Ent'
import {EmployeeTraining} from '../modules/profile/entity_profile/EmployeeTraining_Ent'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/client_profile/ProfilePage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const [fetchedLogin, setFetchedLogin] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)

  const {user, setUser, setUserUpdated} = useUserContext()
  const [, updateState] = useState()
  const navigate = useNavigate()

  const forceUpdate = useCallback(() => updateState(undefined), [])

  function changeUserType(value: any) {
    forceUpdate()
  }

  useEffect(() => {
    async function updateUserInfo() {
      setUserUpdated(true)
      setTimeout(() => {
        setFetchedLogin(true)
      }, 500)
    }
    updateUserInfo()
  }, [])

  useEffect(() => {
    navigate('/')
  }, [loggedIn])

  return (
    <Routes>
      <Route
        element={user && <MasterLayout user={user} setLoggedIn={setLoggedIn} loggedIn={loggedIn} />}
      >
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/' element={<Navigate to='/dashboard' />} />

        <Route
          path='/dashboard'
          element={loggedIn && user ? <DashboardWrapper user={user} /> : <Navigate to='/login' />}
        />

        <Route
          path='profile/*'
          element={<SuspensedView>{user && <ProfilePage />}</SuspensedView>}
        />

        <Route
          path='/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='/candidate-profile/*'
          element={
            <SuspensedView>
              <EmployeeProfile />
            </SuspensedView>
          }
        />
        <Route
          path='/candidate-competencies'
          element={
            <SuspensedView>
              <EmployeeCompetencies />
            </SuspensedView>
          }
        />
        <Route
          path='/candidate-training/*'
          element={
            <SuspensedView>
              <EmployeeTraining />
            </SuspensedView>
          }
        />
        <Route
          path='/login'
          element={
            !loggedIn ? (
              <SuspensedView>
                <Login fetchedLogin={fetchedLogin} setUser={setUser} setLoggedIn={setLoggedIn} />
              </SuspensedView>
            ) : (
              <Navigate to='/' />
            )
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
