import React, {useState, useEffect} from 'react'
import {DynamicSlider} from './DynamicSlider'
import {useUserContext} from '../../../../../UserContext'
import config from '../../../../../config.json'

type EntityRatios = {
  [key: string]: number
}

interface SliderProps {
  min: number
  max: number
  step: number
  subTitle: string
  talentPoolPercentage: number
}

interface GradeRatioCardProps {
  total?: number
  className?: string
  sliders: SliderProps[]
  step?: number
}

interface SliderItem {
  id: number
  value: number
  heading: string
  talentPoolPercentage: number
}

interface SliderData {
  [key: string]: number | string
}

const GradeRatioCard: React.FC<GradeRatioCardProps> = ({total = 0, className, sliders, step}) => {
  const [slidersData, setSlidersData] = useState<SliderItem[]>([])
  const [entityRatioInfo, setEntityRatioInfo] = useState<EntityRatios | undefined>()
  const [initialSliderValues, setInitialSliderValues] = useState<number[]>([])
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertClassName, setAlertClassName] = useState<string>('alert-success') // Class name for the alert
  const {user} = useUserContext()
  const [showSlider, setShowslider] = useState(false)
  async function callEntRatioInfo() {
    if (user?.['user id']) {
      const ratioinfo = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'AllEntityData',
          entity_id: user['user id'],
        }),
      })

      const ratioinfoCont: SliderData = await ratioinfo.json()
      if (ratioinfoCont) {
        const talentPoolPercentage = parseFloat(ratioinfoCont['talent pool percentage'] as string)

        const ratios: EntityRatios = {
          Potential: ratioinfoCont['potential ratio'] as number,
          'Performance (Technical)': ratioinfoCont['technical ratio'] as number,
          'Performance (Appraisal)': ratioinfoCont['appraisal ratio'] as number,
          'Line Manager': ratioinfoCont['line manager ratio'] as number,
        }

        const sliderInfo: SliderItem[] = [
          {id: 1, heading: 'Potential', value: ratios.Potential, talentPoolPercentage: 0},
          {
            id: 2,
            heading: 'Performance (Technical)',
            value: ratios['Performance (Technical)'],
            talentPoolPercentage: 0,
          },
          {
            id: 3,
            heading: 'Performance (Appraisal)',
            value: ratios['Performance (Appraisal)'],
            talentPoolPercentage: 0,
          },
          {id: 4, heading: 'Line Manager', value: ratios['Line Manager'], talentPoolPercentage: 0},
        ]

        setEntityRatioInfo(ratios)

        setSlidersData(sliderInfo)
        //setInitialSliderValues(initialValues)
      }
    }
  }
  async function setEntRatioInfo(data: any) {
    if (user?.['user id']) {
      try {
        const potentialRatio = data[0].value / 100
        const techRatio = data[1].value / 100
        const appraisalRatio = data[2].value / 100
        const lmRatio = data[3].value / 100

        const ratioinfo = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'SetGradeRatios',
            entity_id: user['user id'],
            potential_ratio: potentialRatio.toString(),
            technical_ratio: techRatio.toString(),
            appraisal_ratio: appraisalRatio.toString(),
            line_manager_ratio: lmRatio.toString(),
          }),
        })

        const ratioinfoCont: SliderData = await ratioinfo.json()
        console.log(ratioinfoCont)
        if (ratioinfoCont.message == 'success') {
          setAlertMessage((ratioinfoCont.message as string) || '')
          setAlertClassName('alert-success')
        } else {
          setAlertMessage((ratioinfoCont.message as string) || '')
          setAlertClassName('alert-danger')
        }
      } catch (error) {
        console.error('Error updating grade ratio info:', error)
        setAlertMessage('Error Updating Grade Ratio')
        setAlertClassName('alert-danger')
      }
    } else {
      setAlertMessage('Error Updating Grade Ratio')
      setAlertClassName('alert-danger')
    }
  }

  useEffect(() => {
    callEntRatioInfo()
  }, [])

  const updatedInfo = (data: any) => {
    // console.log('IN THE GRADE RATIO CONTROLLER')
    // console.log(data)
    setEntRatioInfo(data)
  }

  const toggleShowEditButton = () => {
    setShowslider((prevState) => !prevState)
  }
  return (
    <div className={`${className} h-100`}>
      {slidersData.length > 0 ? (
        <DynamicSlider
          sliders={slidersData}
          initialSliderValues={initialSliderValues}
          className='my-card'
          title='Score Weight Controller'
          updateDataFunc={updatedInfo}
          alertMessage={alertMessage}
          alertClassName={alertClassName}
        />
      ) : (
        <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center align-items-center '>
          <span
            className='spinner-border spinner-border-lg spinner-info'
            style={{
              width: '80px',
              height: '80px',
              border: ' var(--bs-spinner-border-width) solid #01a982',
              borderRightColor: 'var(--bs-light)',
              borderWidth: '10px',
            }}
          ></span>
        </div>
      )}
    </div>
  )
}

export {GradeRatioCard}
