import React, {useState} from 'react'
interface SliderItem {
  id: number
  value: number
  heading: string
  step?: number
}
interface DynamicSliderProps {
  sliders: SliderItem[]
  className: string
  title: string
  initialSliderValues?: any
  updateDataFunc?: any
  alertMessage?: string
  alertClassName?: string // New prop for alert class name
}
const DynamicSlider: React.FC<DynamicSliderProps> = ({
  sliders,
  className,
  title,
  updateDataFunc = (data: any) => console.log(data),
  initialSliderValues = 0,
  alertMessage = '',
  alertClassName = '', // Assigning default value as an empty string
}) => {
  const [sliderValues, setSliderValues] = useState<SliderItem[]>(sliders)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const handleSliderChange = (id: number, value: number) => {
    const updatedSliders = sliderValues.map((slider) =>
      slider.id === id ? {...slider, value} : slider
    )
    const totalValue = updatedSliders.reduce((total, slider) => total + slider.value, 0)
    if (totalValue <= 100) {
      setSliderValues(updatedSliders)
    }
  }
  const totalValue = sliderValues.reduce((total, slider) => total + slider.value, 0)
  const logSliderValues = () => {
    updateDataFunc(sliderValues)
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 3000)
  }
  const handleResetValues = () => {
    setSliderValues(sliders)
  }
  return (
    <div className={`card ${className}  h-100`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>
      <div className='card-body pt-0 overflow-scroll h-100'>
        <table className='table-responsive table table-row-dashed table-row-gray-300 align-middle gs-0  gy-2 table-fixed'>
          <tbody>
            {sliderValues.map((slider, index) => (
              <tr key={slider.id + index}>
                <td>{slider.heading}</td>
                <td>
                  <input
                    type='range'
                    value={slider.value}
                    min={0}
                    max={100}
                    step={slider.step ? slider.step : 1}
                    className='form-range'
                    onChange={(e) => handleSliderChange(slider.id, parseInt(e.target.value))}
                  />
                </td>
                <td>{slider.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='sum-container'>
          <span>Total: {totalValue}</span>
        </div>
        <button className='btn btn-bg-light mt-3 m-1' onClick={handleResetValues}>
          Reset
        </button>
        <button className='btn btn-primary mt-3 m-1' onClick={logSliderValues}>
          Submit values
        </button>
        {showAlert && <div className={`alert ${alertClassName} mt-3`}>{alertMessage}</div>}
      </div>
    </div>
  )
}
export {DynamicSlider}
