import {FC, useEffect, useState} from 'react'

import {useUserContext} from '../../../../UserContext'

type Props = {
  warningText?: string
  warningColor?: string
  errorText?: string
  errorColor?: string
  spinnerWidth?: string
  icon?: string
  warningTime?: number
  callAgain?: any
  maxReloads?: number
  canReload?: boolean
  reloadFunc?: (params: any) => any
  reloadFuncProps?: any
}

const WarningSpinner: FC<Props> = ({
  warningText = 'Please refresh the page',
  warningColor = 'text-warning',
  errorText = 'We found an issue. Please contact the system admin or try reloading the page!',
  errorColor = 'text-danger',
  icon = 'fa-triangle-exclamation',
  spinnerWidth = '50px',
  warningTime = 5000,
  maxReloads = 5,
  canReload = false,
  reloadFunc,
  reloadFuncProps,
}) => {
  const [showWarning, setShowWarning] = useState(false) // State to control showing the warning message
  const [showReload, setReloadIndex] = useState(0)
  const [textForWarning, setTextForWarning] = useState(warningText)
  const [textWarningColor, setTextWarningColor] = useState(warningColor)

  const {user} = useUserContext()

  const reloadButton = document.getElementById('reloadButton')

  const reloadFunction = () => {
    setShowWarning(false)
    setReloadIndex(showReload + 1)
    reloadFunc?.(reloadFuncProps)
    //console.log('printing reload ' + showReload)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWarning(true)
    }, warningTime)

    // if (showReload != null && reloadButton != null) {
    //   if (showReload >= maxReloads) {
    //     reloadButton.style.display = 'none'
    //     setTextForWarning('Please JUST refresh the page from the top')
    //     // reloadFunc?.(reloadFuncProps)
    //     // warningText = 'Please JUST refresh the page from the top'
    //   }
    // }

    return () => clearTimeout(timer)
  }, [
    showWarning,
    // ,showReload
  ])

  useEffect(() => {
    if (showReload != null && reloadButton != null) {
      if (showReload >= maxReloads) {
        // reloadButton.style.display = 'none'
        setTextForWarning(errorText)
        setTextWarningColor(errorColor)
      }
    }
  }, [showReload])

  return (
    <div className='card-body p-0 d-flex justify-content-center h-75 w-100'>
      {showWarning ? (
        <div
          className='
           d-flex flex-column flex-center w-100 h-75'
          role='alert'
        >
          <i className={`fa-solid  fs-1 ${textWarningColor} ${icon} `} role='alert'></i>
          <span className={`text-center ${textWarningColor} pb-1`}>{textForWarning}</span>
          {canReload && showReload < maxReloads && (
            <a
              onClick={reloadFunction}
              className='btn btn-icon btn-outline-light'
              id='reloadButton'
            >
              <i className='fa-solid fs-1 text-medium fa-refresh'></i>
            </a>
          )}
        </div>
      ) : (
        <div className='card-body pt-2 pb-4 d-flex justify-content-center '>
          <span
            className='spinner-border spinner-border-lg spinner-info'
            style={{
              width: spinnerWidth,
              height: spinnerWidth,
              border: ' var(--bs-spinner-border-width) solid #01a982',
              borderRightColor: 'var(--bs-light)',
              borderWidth: '10px',
            }}
          ></span>
        </div>
      )}
    </div>
  )
}

export default WarningSpinner
